import * as React from 'react';
import { memo } from 'react';
import SVG from 'react-inlinesvg';
import type Lucide from 'lucide-react';

type IconProps = {
  name: keyof typeof Lucide;
  className?: string;
  onClick?: () => void;
};

const Icon = memo(({ name, className, onClick }: IconProps) => {
  const hasDimensions = className?.includes('h-') || className?.includes('w-');
  const fallback = (
    <div
      className={className}
      style={{
        height: hasDimensions ? undefined : 24,
        width: hasDimensions ? undefined : 24,
      }}
    />
  );
  return (
    <SVG
      onClick={() => onClick && onClick()}
      src={`/icon/${name}.svg`}
      className={className}
      loader={fallback}
    />
  );
});

export default Icon;
